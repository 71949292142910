<template>
  <div>
    <!-- <vs-button
      class="mt-2"
      color="success"
      @click="handleGetData()"
    >Filter</vs-button> -->
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>
        <!-- <vs-th sort-key width="5%">No</vs-th> -->
        <vs-th sort-key="code">Customer</vs-th>
        <vs-th sort-key="address">Address</vs-th>
        <vs-th sort-key="nik_name">Contact</vs-th>
        <vs-th sort-key="is_pkp">Tax & Invoice</vs-th>
        <vs-th sort-key="virtual_account">Virtual Account</vs-th>
        <vs-th>CreatedOn</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-no-wrap">
            <feather-icon
              title="Address List"
              icon="ListIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current"
              @click.stop="handleCustomerAddress(tr.id)"
            />
            <feather-icon
              title="Edit"
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="
                handleEdit(
                  tr.id,
                  tr.status_approval,
                  tr.territory_code + ' - ' + tr.territory_name
                )
              "
            />
            <!-- <feather-icon
              v-if="tr.status_approval == '0' || tr.status_approval == '3'"
              title="Send for Approval"
              icon="SendIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              class="ml-2"
              @click.stop="handleSendForApproval(tr.id)"
            /> -->
            <!-- <feather-icon
              title="Delete"
              icon="TrashIcon"
              svgClasses="w-5 h-5 hover:text-danger stroke-current"
              class="ml-2"
              @click.stop="handleDelete(tr.id)"
            /> -->
          </vs-td>
          <!-- <vs-td :data="tr.code">{{((length*page)-length )+ indextr+1}}</vs-td> -->
          <vs-td :data="tr.code">
            <span v-if="tr.status_approval == '2'"><span v-if="tr.code_external == ''">Code : {{ tr.code }}</span><span v-else>Code : {{ tr.code }} <br>Code External : {{ tr.code_external }}</span></span>
            <span v-else>Code NOO : {{ tr.code_noo }}</span>
            <br />
            Outlet Name : {{ tr.name }}
            <br />
            Channel : {{ tr.customer_category_name }}
            <br />
            Delivery Type : {{ tr.delivery_type_name }}
            <br />
            Group 1 : {{ tr.customer_group_name1 }}
            <br />
            Group 2 : {{ tr.customer_group_name2 }}
            <br />
            Group 3 : {{ tr.customer_group_name3 }}
            <br />
            Pricing Group : {{ tr.pricing_group_code_name }}
            <br />
            Payment Type : {{ tr.payment_type }}
            <br />
            Payment Term : {{ tr.payment_term_name }} ({{ tr.payment_term_day }}
            day)
            <br />
            <!-- Territory : {{ tr.territory_agg }}
            <br /> -->
            Invoice Model : {{ tr.invoice_model }}
            <br />
            Credit Limit :
            {{
              tr.customer_limit_amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }}
            <br />
            Business Partner : {{ tr.bussiness_partner }}
            <br />
            Status : {{ tr.status }}
            <br />
            Status Approval :
            {{
              tr.status_approval_helper == "02"
                ? "-"
                : tr.string_status_approval
            }}
            <br />
            Reason :
            {{ tr.status_approval_helper == "02" ? "-" : tr.remark_approval }}
            <br />
            Priority : {{ tr.priority_type }}
            <br />
            Status Order Block : {{ tr.status_block }}<br />
            Territory : {{ tr.territory_code }} - {{ tr.territory_name }}
          </vs-td>
          <vs-td :data="tr.address">
            Country : {{ tr.country }}
            <br />
            Province :
            {{ tr.province }} <br />
            City :
            {{ tr.city }} <br />
            District :
            {{ tr.district }} <br />
            Sub-District :
            {{ tr.sub_district }} <br />
            Address :
            {{ tr.address }} <br />
            Postal Code :
            {{ tr.postal_code }} <br />
            Transport Zone :
            {{ tr.transport_zone }} <br />
            Rt / Rw :
            {{ tr.rt_rw }} <br />
            Street No :
            {{ tr.street_no }} <br />
            Latitude :
            {{ tr.latitude }} <br />
            Longitude :
            {{ tr.longitude }}
          </vs-td>
          <vs-td :data="tr.nik_name">
            PIC Name : {{ tr.nik_name }}
            <br />
            Phone : {{ tr.phone }}
            <br />
            Mobile : {{ tr.mobile }}
            <br />
            Email : {{ tr.email }}
            <br />
            Fax : {{ tr.fax }}
          </vs-td>
          <vs-td>
            <div>
              Max Open Bill : {{ tr.max_open_bill === 0 ? 'Unlimited' : tr.max_open_bill }}
              <br />
              Invoice Model : {{ tr.invoice_model }}
              <br />
              Tax Collector : {{ tr.is_tax_collector == '1' ? 'YES' : 'NO' }}
              <br />
              Tax Exempt : {{ tr.is_tax_exempt == '1' ? 'YES' : 'NO' }}
              <br />
              PKP : {{ tr.is_pkp == '1' ? 'YES' : 'NO' }}
              <br />
              NPWP : {{ tr.npwp }}
              <br />
              NPWP Name : {{ tr.npwp_name }}
              <br />
              SPPKP : {{ tr.sppkp }}
              <br />
              Tax Address : {{ tr.tax_address }}
              <br />
              Domisili : {{ tr.domisili }}
            </div>
          </vs-td>
          <vs-td>
            <div :key="idx" v-for="(dt, idx) in tr.customer_account">
              Bank Name : {{ dt.bank_name }}
              <br />
              Account Number : {{ dt.account_number }}
              <br />
              <hr>
            </div>
          </vs-td>
          <vs-td :data="tr.created_at">
            Created On : {{ tr.created_at }} <br />
            Created By : {{ tr.created_by }} <br />
            Updated On : {{ tr.updated_at }} <br />
            Updated By : {{ tr.updated_by }} <br />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    customerCategoryId: {
      type: Number,
    },
    territorryId: {
      type: Number,
    },
    detail: {
      type: Boolean,
    },
    statusFilter: {
      type: Number,
    },
    statusApproval: {
      type: String,
    },
    statusFrom: {
      type: String,
    },
    paymentTypeFilter: {
      type: String,
    },
  },
  data() {
    return {
      baseUrl: "/api/oms/v1/sfa-master/customer",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      console.log("12");
      this.table.page = page;
      if (this.statusFilter > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleCreate() {
      this.customerId = null;
      this.detail = true;
    },
    getData() {
      console.log("loading");
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            customer_category_id: this.customerCategoryId,
            territory_id: this.territorryId,
            type: "End Customer",
            status_approval: this.statusApproval,
            payment_type: this.paymentTypeFilter,
          },
        })
        .then((resp) => {
          console.log(resp)
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    // handleDelete(id) {
    //   this.deleteId = id;
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "danger",
    //     title: `Confirm`,
    //     text: "Please confirm to delete this customer data",
    //     accept: this.acceptDelete,
    //   });
    // },
    // acceptDelete() {
    //   this.$vs.loading();
    //   this.$http
    //     .delete(this.baseUrl + "/" + this.deleteId)
    //     .then((resp) => {
    //       this.$vs.loading.close();
    //       if (resp.code == 200) {
    //         this.$vs.notify({
    //           color: "success",
    //           title: "Success",
    //           text: "The customer data was successfully deleted",
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       } else {
    //         this.$vs.notify({
    //           color: "danger",
    //           title: "Error",
    //           text: resp.message,
    //           position: "top-right",
    //           iconPack: "feather",
    //           icon: "icon-x-circle",
    //         });
    //       }
    //       this.getData();
    //     })
    //     .catch((error) => {
    //       this.$vs.loading.close();
    //       console.log(error);
    //     });
    // },
    handleEdit(id, status_approval, territory) {
      console.log(territory);
      this.$emit("edit", id, status_approval, territory);
    },
    handleSendForApproval(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirm`,
        text: "Are you sure send for approval?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .post(this.baseUrl + "-approval/" + id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "Success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    handleCustomerAddress(id) {
      console.log(id);
      if (this.statusFrom == "index-all") {
        this.$router.push("/master/customers-address/" + id);
      } else {
        this.$router.push("/master/customer-address/" + id);
      }
    },
  },
  watch: {
    // customerCategoryId() {
    //   this.getData();
    // },
    // territorryId() {
    //   this.getData();
    // },
    statusFilter() {
      this.getData();
    },
    detail() {
      if (this.detail == false) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
